<!-- 视频组件 -->
<template>
  <div id="playercontainer"></div>
</template>
<script>
export default {
  props: {
    // 是否禁止快进快退
    prohibitionOfAdvanceAndRetreat: {
      type: Boolean,
      default: false,
    },
    // 是否自动播放
    autoplay: {
      type: Boolean,
      default: false,
    },
    // 是否循环播放
    loop: {
      type: Boolean,
      default: false,
    },
    video_Msg: {
      type: Object | String,
    },
  },
  name: "ckplayer",
  data() {
    return {
      player: null,
      nowPlayTime: 0, //当前播放时间
      startTime: 0, //默认跳转时间
      videoImg: "",
      buffer:'', // 获取视频缓冲比率,0-100
    };
  },
  watch: {
    video_Msg(val) {
      this.palyInit();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.palyInit();
      this.screen()
    });
  
  },
  // beforeDestroy(){
  //     //在 beforeDestroy 钩子移除beforeunload事件
  //     this.$emit('handleLeave',this.nowPlayTime)
  //     this.destroyedBeforeunloadHandler()  
  // },
  methods: {
    palyInit() {
      
      cyberplayer("playercontainer").setup({
        width: "100%",
        height: "198px",
        title: "播放器",
        file:  this.video_Msg, // hls播放地址（×一定要支持跨域访问，否则要设置primary参数）
        // image: "http://cyberplayer.bcelive.com/thumbnail.jpg", // 视频截图
        autostart: true,
        stretching: "uniform",
        repeat: true,
        volume: 100,
        controls: true,
        starttime: 0,
        // primary: "flash", // 强制使用flash来播放，不设置的话则默认高优使用H5进行播放
        ak: "58817f2605594e20a10a542ac8d570b6",
      });
      
    },
    screen(){
      let self = this
      cyberplayer("playercontainer").onError(function(event){ 
        // self.$toast.fail('播放需要广电网络！')
        console.log('无法播放')
      });
    },
  },
};
</script>
<style scoped>
#ysp_ckplayer {
  width: 100%;
  height: 20rem;
}
</style>