<template>
  <div class="street-info">
    <van-tabs
      color="#1989fa"
      title-active-color="#1989fa"
      v-model="active"
      @click="onClick"
    >
      <van-tab
        v-model="active"
        :title="item.title"
        v-for="(item, index) in vlist"
        :key="index"
      >
        <div class="list-con">
          <load-list :interFace="interFace">
            <template v-slot:list="props">
              <div
                v-for="(item, index) in props.list"
                :key="index"
                class="list"
              >
                <div class="border">
                  <div class="title" @click="toArticleDetail(item.id)">
                    {{ item.title }}
                  </div>
                  <!-- 视频 -->
                  <div v-if="item.tpe == 4">
                    <Cyberplayer :video_Msg="item.video_path"></Cyberplayer>
                  </div>
                  <!-- 图片 -->
                  <div
                    v-if="item.tpe == 2 && item.images.length > 0"
                    class="list-img"
                    @click="toArticleDetail(item.id)"
                  >
                    <img
                      :src="$IMG_PATH + pitem"
                      alt=""
                      v-for="(pitem, index) in item.images"
                      :key="index"
                    />
                  </div>
                  <!-- 图集 -->
                  <div
                    v-if="item.tpe == 3"
                    class="list-img"
                    @click="toArticleDetail(item.id)"
                  >
                    <img
                      :src="$IMG_PATH + pitem"
                      alt=""
                      v-for="(pitem, index) in item.images"
                      :key="index"
                    />
                  </div>
                  <!-- 文字 -->
                  <div
                    v-if="item.tpe == 1"
                    class="list-text"
                    @click="toArticleDetail(item.id)"
                  >
                    {{ item.brief }}
                  </div>
                  <div class="foot" @click="toArticleDetail(item.id)">
                    <div class="foot1">
                      <span class="iconfont"></span>
                      <span class="">{{ item.add_time }}</span>
                    </div>
                    <div class="item-foot">
                      <div>
                        <span class="iconfont icon-check-line"></span>
                        <span class="ml-10">{{ item.click_count }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="getimg" v-if="item.tpe==2">
                    <img :src="$IMG_PATH + item.thumb_img" alt="">
                </div>
              </div>
            </template>
          </load-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getCategory, getCategoryInfo, getInform } from "../api";
import LoadList from "@/components/SearchLoadList";
import Cyberplayer from "../../../components/cyberplayer/index";

export default {
  components: {
    LoadList,
    Cyberplayer,
  },
  data() {
    return {
      active: 0,
      id: 14,
      vlist: [
        { id: 14, image: "", title: "通知公告" },
        { id: 15, image: "", title: "工作发布" },
        { id: 34, image: "", title: "主题活动" },
      ],
      interFace: {
        api: getInform,
        params: {
          cid: 14,
        },
      },
      articleList: [],
    };
  },
  created() {
    // this.init();
  },
  methods: {
    onClick(name, title) {
      // console.log(name, title);
      this.id = this.vlist[name].id;
      this.$set(this.interFace.params, "cid", this.id);
    },
    toArticleDetail(id) {
      if (this.active == 2) {
        this.$router.push({
          name: "topic",
          params: {
            id: id,
          },
        });
      } else {
        this.$router.push({
          name: "articleDetail",
          params: {
            id: id,
          },
        });
      }
    },
    init() {
      //   let params = {
      //     id: 62,
      //   };
      //   getCategory(params).then((res) => {
      //     this.vlist = res.data;
      //     console.log(res);
      //     this.id = res.data[0].id;
      //     this.getList();
      //   });
      this.getList();
    },
    getList() {
      let params = {
        cid: this.id,
      };
      getInform(params).then((res) => {
        this.articleList = res.data.items;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.street-info {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .list {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0.1rem;
    margin-top: 0.05rem;
    display: flex;
    justify-content: space-between;
    .border {
      width: 100%;
      padding: 0.24rem 0.26rem;
      box-sizing: border-box;
      overflow: hidden;
      .title {
        font-size: 0.28rem;
        color: #333;
        letter-spacing: 0.02rem;
        // font-weight: 600;
        margin-bottom: 0.15rem;
        max-height: 1rem;
        line-height: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .list-text {
        width: 100%;
        max-height: 1.5rem;
        line-height: 0.5rem;
        font-size: 0.24rem;
        color: #8b8b8b;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .list-img {
        width: 100%;
        height: 1.3rem;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        // justify-content: space-between
        overflow-x: auto;
        img {
          width: 32%;
          margin: 0 0.14rem 0 0;
        }
      }
      video {
        width: 100%;
        border-radius: 0.2rem;
      }
      .foot {
        color: #8b8b8b;
        font-size: 0.24rem;
        margin-top: 0.3rem;
        display: flex;
        // justify-content: space-between;
        line-height: 120%;
        .foot1 {
          padding-right: 1rem;
        }
        .item-foot {
          display: flex;
          div {
            margin-right: 0.2rem;
          }
        }
        .item-foot-4 {
          width: 0.27rem;
          height: 0.15rem;
        }
        .ml-10 {
          margin-left: 0.1rem;
        }
      }
    }
    .getimg{
      height: 1.9rem;
      width: 5.2rem;
      padding: 0.2rem 0.2rem 0 0;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        border-radius: 0.2rem
      }
    }
  }
  div.list:first-child {
    border-radius: 0.14rem 0.14rem 0 0;
  }
  div.list:last-child {
    border-radius: 0 0 0.14rem 0.14rem;
  }
}
</style>